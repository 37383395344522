/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DrawerQueryVariables = {};
export type DrawerQueryResponse = {
    readonly me: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"Drawer_me">;
    } | null;
};
export type DrawerQuery = {
    readonly response: DrawerQueryResponse;
    readonly variables: DrawerQueryVariables;
};



/*
query DrawerQuery {
  me {
    id
    ...Drawer_me
  }
}

fragment Drawer_me on UserType {
  firstName
  lastName
  clientID
  avatarUrl: profileImageUrl(width: 100, height: 100)
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Drawer_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserType",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientID",
            "storageKey": null
          },
          {
            "alias": "avatarUrl",
            "args": [
              {
                "kind": "Literal",
                "name": "height",
                "value": 100
              },
              {
                "kind": "Literal",
                "name": "width",
                "value": 100
              }
            ],
            "kind": "ScalarField",
            "name": "profileImageUrl",
            "storageKey": "profileImageUrl(height:100,width:100)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7fa88761fb6174ce8c67b4ef34809b9",
    "id": null,
    "metadata": {},
    "name": "DrawerQuery",
    "operationKind": "query",
    "text": "query DrawerQuery {\n  me {\n    id\n    ...Drawer_me\n  }\n}\n\nfragment Drawer_me on UserType {\n  firstName\n  lastName\n  clientID\n  avatarUrl: profileImageUrl(width: 100, height: 100)\n}\n"
  }
};
})();
(node as any).hash = '1c7b98edfb92cef5b8e03b2b5b9e55aa';
export default node;
