import React from 'react'
import { Header, Box, Flex, FlexProps } from 'ui-components'
import Drawer from '../components/Drawer'
import Head from 'next/head'
interface PageContainer extends FlexProps {
  title: string
  children: React.ReactNode
  showNav?: boolean
  collapse?: boolean
  headerRight?: React.ReactNode
}
export default function PageContainer({
  title,
  children,
  showNav = true,
  headerRight = null,
  collapse = false,
  ...props
}: PageContainer) {
  const startComponent = showNav ? <Drawer /> : null
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} key="title" />
      </Head>
      <Header startComponent={startComponent} endComponent={headerRight}>
        {title}
      </Header>
      {collapse ? (
        <Flex flexDirection="column" flex={1}>
          {children}
        </Flex>
      ) : (
        <Flex flexDirection="column" flex={1} p="6" {...props}>
          {children}
        </Flex>
      )}
    </>
  )
}
