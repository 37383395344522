/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Drawer_me = {
    readonly firstName: string;
    readonly lastName: string;
    readonly clientID: string;
    readonly avatarUrl: string | null;
    readonly " $refType": "Drawer_me";
};
export type Drawer_me$data = Drawer_me;
export type Drawer_me$key = {
    readonly " $data"?: Drawer_me$data;
    readonly " $fragmentRefs": FragmentRefs<"Drawer_me">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Drawer_me",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientID",
      "storageKey": null
    },
    {
      "alias": "avatarUrl",
      "args": [
        {
          "kind": "Literal",
          "name": "height",
          "value": 100
        },
        {
          "kind": "Literal",
          "name": "width",
          "value": 100
        }
      ],
      "kind": "ScalarField",
      "name": "profileImageUrl",
      "storageKey": "profileImageUrl(height:100,width:100)"
    }
  ],
  "type": "UserType",
  "abstractKey": null
};
(node as any).hash = '9112a8c072dda717687a650b7133b910';
export default node;
